<template>
	<div id="app">
		<v-main>
			<!-- 上布局 -->
			<v-row>
				<v-col>
					<!-- 上布局内容 -->
					<component class="mr-6" is="top" />
				</v-col>
			</v-row>
			<!-- 下布局 -->
			<v-row>
				<!-- 左侧 -->
				<v-col :cols="show_left?show_left_right?2:1:0" style="display: flex;">
					<v-col :cols='show_left_right?3:12' style="max-width: 72px; margin: 0px;padding: 0px;">
						<v-card :height="800" :width="72">
							<v-col align="center" v-for="item in menu_left[currentRouteName]" :key="item.name"
								:style="`${item.component==functionType?'background-color: aliceblue;':''}`"
								@click="menuLeft(item.component)">
								<v-icon color="#1976d2" style="width: 100%;">{{item.icon}}</v-icon>
								<span>{{item.name}}</span>
							</v-col>
						</v-card>
					</v-col>
					<v-col cols='9' style="margin: 0px;padding: 0px;" v-if="show_left_right">
						<v-card :height="800">
							<v-card-title>
								请选择
							</v-card-title>
							<v-divider></v-divider>
							<v-card-text style="overflow-y: auto;max-height: 720px;">
								<v-row v-for="(item,index) in principles" :key="index">
									<v-col style='padding: 0px 0px'>
										<strong @click="updatePjo(index+1)">
											({{index+1}}) {{item.text}}
										</strong>
									</v-col>
								</v-row>

							</v-card-text>
						</v-card>
					</v-col>

					<div class="p-editor-btn">
						<div class="p-editor-btn-bg">
							<img src="@/assets/collapse-btn-light.svg" width="39" height="620"
								style="pointer-events: none;">
						</div>
						<div class="p-editor-btn-icon" @click="show_left_right=!show_left_right">
							<v-icon color="#1976d2" v-if="show_left_right">mdi-chevron-left</v-icon>
							<v-icon color="#1976d2" v-if="!show_left_right">mdi-chevron-right</v-icon>
						</div>
					</div>
				</v-col>
				<!-- 中间 -->
				<v-col :cols="show_left?show_right?show_left_right?8:9:10:show_right?10:12">
					<v-card height="800" style="overflow: auto">
						<v-card v-for="(item,i) in principles" :key="i" :id="item.value">
							<v-row style="margin-top: 10px;">
								<v-col cols="12">
									<strong style="margin-left: 10px;">({{i+1}}) {{item.text}}</strong>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<strong style="margin-left: 50px;">原理定义</strong>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<span style="margin-left: 50px;">{{item.definition}}</span>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<strong style="margin-left: 50px;">原理内容</strong>
									<v-row v-for="(init,index) in CheckboxArrList" v-if="init.inventiveNumber==i+1"
										:key="init.inventiveOption">
										<v-col cols="12">
											<span style="margin-left: 50px;">{{init.inventiveOption}}</span>
											<v-row>
												<v-img style="margin-left: 50px;width: 150px;margin-bottom: 10px;"
													v-for="imgs in init.inventiveImage" :key="imgs" :src="imgs"></v-img>
											</v-row>
										</v-col>
									</v-row>
								</v-col>
							</v-row>

						</v-card>
						<v-row v-if="CheckboxArrList.length==0">
							<v-col cols="2">
								<strong>暂无数据</strong>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
				<!-- 右侧 -->
				<v-col :cols="show_right?2:0">
					<!-- 右侧内容 -->
					<v-card v-show="show_right" height="800" style="overflow: auto">
						<v-card flat class="zdy_bjt" style="">
							<v-row style="height: 90px;">
								<v-col cols="12" elevation="1">
									<img src="../../assets/triz.png" style="width: 100%; margin-left: -22%;" />
									<v-divider class="mx-4 mb-1 " style="margin-top: -70px;"></v-divider>
								</v-col>
							</v-row>
							<v-card height='700' style=" overflow: auto;background: rgba(255, 255, 255, 0.0);" flat>
								<v-col ols="12">
									<v-card elevated='20' style="background: rgba(167, 167, 167, 0.1);"
										v-for="item in variants[0].val" :key="item.path"
										v-if="item.path==currentRouteName">
										<v-col ols="12">
											<span>{{item.caption}}</span>
										</v-col>
									</v-card>
									<v-card elevated='20' style="background: rgba(167, 167, 167, 0.1);"
										v-for="item in variants[1].val" :key="item.path"
										v-if="item.path==currentRouteName">
										<v-col ols="12">
											<span>{{item.caption}}</span>
										</v-col>
									</v-card>
								</v-col>
							</v-card>
						</v-card>
					</v-card>
				</v-col>
			</v-row>
		</v-main>
	</div>
</template>

<script>
	import welcomePage from './component/welcomePage.vue'
	import top from "./component/top.vue"
	import {
		menu_left,
		variants,
	} from '@/store/default.js'
	import {
		principles
	} from '@/store/altshuller.js'
	import {
		getAllInvention,
	} from '@/api/invention'
	export default {
		components: {
			top,
			welcomePage,
		},
		data: () => ({
			functionType: 'MyList',
			CheckboxArrList: [],
			currentRouteName: '',
			menu_left: menu_left,
			principles: principles,
			variants: variants,
			show_left: true,
			show_right: true,
			show_left_right: true,


		}),
		computed: {},
		created() {
			this.currentRouteName = this.$route.name;
			this.initialize()
		},
		watch: {
			functionType() {
				this.initialize()
			},
		},
		methods: {
			// 左侧菜单点击事件
			menuLeft(con) {
				this.functionType = con
				this.show_left_right = true
			},
			// 子页面点击
			updatePjo(item) {
				// console.log(item)
				const section = document.getElementById(item);
				if (section) {
					section.scrollIntoView({
						behavior: 'smooth'
					});
				}
			},
			initialize() {
				let that = this
				that.is_choice = false
				getAllInvention().then((res) => {
						// console.log('initialize',res.data)
						that.CheckboxArrList = res.data
					})
					.catch(() => {

					})
			},
		},
	}
</script>

<style lang="scss">
	@import "../../styles/one_triz/index.scss";
	@import "../../styles/new_triz/index.scss";
</style>