<template>
	<v-container>
		<v-row v-for="item in variants" :key="item.title">
			<v-col v-for="(variant, k) in item.val" :key="k" cols="auto" v-if="variant.path==router">
				<v-row >
					<v-col cols="12" align="center">
						<!-- 左侧内容 -->
						<div class="text-h6 mb-1" style="height: 60px">{{ variant.name }}</div>
						<div class="text-caption" style="height: 90px;overflow: auto;">{{ variant.caption }}
						</div>
					</v-col>
					<v-col cols="12" align="center">
						<!-- 右侧内容 -->
						<v-img :src="require(`@/assets/tools/${variant.path}.png`)" :alt="variant.name"
							style="border-radius: 24px;width: 200px;"></v-img>
					</v-col>
					<v-col cols="12" align="center" v-if="$route.name!='science' && $route.name!='inventive'">
						<!-- 右侧内容 -->
						<v-btn style="width: 160px;height: 36px;margin: 0 auto 16px;letter-spacing: 3px;color: white;font-size: 20px;"
							color="red" @click="newProject">{{router!='FunctionalCropping'?'新建项目':'新建裁剪' }}</v-btn>
					</v-col v-if="$route.name!='science' && $route.name!='inventive'">
					<v-col cols="12" align="center" v-if='cardList&&cardList.length>0'>
						<h5 style="color: #1976d2;">打开记录</h5>
						<div  class="tab_content_wel" >
							<v-card v-for="(item, index) in cardList" :key="index" v-if="index<3" align="center"  elevation="2" height="200" 
							class="my-2 card_item" max-width="150" @click="updatePjo(item)">
								<v-img v-if="item.image" height="112"  :src="item.image"></v-img>
								<v-img v-else height="112"  src="../../../assets/defaultImg.png"></v-img>
								<v-card-title class="text-subtitle-2 text_title">{{ item.toolsName }}</v-card-title>
							</v-card>
						</div>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import {
		variants
	} from '@/store/default'
	import {
		showTools,
		insertTools
	} from '@/api/account'
	export default {
		components: {

		},
		props: {
			router: {
				type: String
			},
		},
		model: {
			event: 'change',
		},
		data() {
			return {
				variants: [...variants],
				cardList: []
				// triz: this.triz,
			}
		},
		mounted() {
			this.showToolslist()
		},
		methods: {
			change() {
				this.$emit('change', this.router)
			},
			showToolslist() {
				let that = this
				showTools(this.router)
					.then((response) => {
						that.cardList = response.data
					})
					.catch(() => {})
			},
			newProject(){
				this.$emit('newProject')
			},
			updatePjo(item){
				this.$emit('updatePjo',item)
			}
		},
	}
</script>
