<template>
	<v-card height="50" width="100%">
		<v-row>
			<v-col cols="2" align="left" @click="goHome">
				<v-img style="width: 80px;height: 60px;margin: -6px 6px 0px 0px;float: left;" src="@/assets/triz.png">
				</v-img>
				<!-- <h3 style="color: #1976d2;">发明问题解决理论</h3> -->
			</v-col>
			<v-col cols="8" align="left" v-if="$route.name!='science'">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on">
							<v-btn @click="onUndoClick" text style="height: 20px;"
								:disabled='!is_choice || !trizMutations.length || (trizMutations.length && mutationIndex == 0)'>
								<v-icon color="#1976d2">mdi-arrow-left-top</v-icon>
							</v-btn>
						</span>
					</template>
					<span style="float: left;">撤销编辑</span>
				</v-tooltip>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on">
							<v-btn @click="onRedoClick" text style="height: 20px;"
								:disabled='!is_choice || !trizMutations.length || (trizMutations.length && mutationIndex >= trizMutations.length - 1)'>
								<v-icon color="#1976d2">mdi-arrow-right-top</v-icon>
							</v-btn>
						</span>
					</template>
					<span>恢复编辑</span>
				</v-tooltip>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on">
							<v-btn @click="saveTools" style="height: 20px;" text :disabled="!is_choice">
								<v-icon color="#1976d2">mdi-content-save-all-outline</v-icon>
							</v-btn>
						</span>
					</template>
					<span>保存</span>
				</v-tooltip>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on">
							<v-btn @click="showHome" style="height: 20px;" text :disabled="!is_choice">
								<v-icon color="#1976d2">mdi-folder-star-multiple-outline</v-icon>
							</v-btn>
						</span>
					</template>
					<span>首页</span>
				</v-tooltip>
			</v-col>
			<v-col cols="2" align="center">
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
	export default {
		components: {

		},
		props: {
			is_choice: false
		},
		model: {
			event: 'change',
		},
		data() {
			return {
				triz_toole: {}
			}
		},
		mounted() {

		},

		computed: {
			trizMutations() {
				return this.$store.state.trizMutations
			},
			mutationIndex() {
				return this.$store.state.mutationIndex
			},
			triz() {
				return this.$store.state.triz
			},
		},
		methods: {
			onUndoClick() {
				console.log('trizMutations', this.trizMutations)
				console.log('mutationIndex', this.mutationIndex)
				this.$store.commit('undo')
				this.triz_toole = this.triz
				this.change()
			},
			onRedoClick() {
				this.$store.commit('redo')
				this.triz_toole = this.triz
				this.change()
			},
			saveTools() {
				this.$emit('saveTools')
			},
			showHome() {
				this.$emit('showHome')
			},
			change() {
				this.$emit('change', this.triz_toole)
			},
			goHome() {
				this.$router.push('/');
			}
		},
	}
</script>